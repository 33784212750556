import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonPrimary from 'components/button-primary';
import { unsubscribeFromChatroom } from 'modules/relationships/home/chat/actions';
import { logout } from 'modules/auth/actions';
import { AppDispatch } from 'redux/typings';
import { useHistory } from 'react-router';
import useActionStatus from 'hooks/useActionStatus';
import { selectLoadingScreenErrors } from 'modules/error/selectors';
import { reportLoadingErrors } from 'modules/error/actions';
import { ErrorData } from 'modules/error/typings';
import localizedStrings from 'localization/en';
import { getActiveQuest } from 'modules/loading-screen/quests/actions';
import { getScheduleCommit } from 'modules/loading-screen/schedule-commit/actions';
import { getSchedules } from 'modules/loading-screen/schedules/actions';

type ActionsButtons = {
  hasQuitButton?: boolean;
  hasReportAdminButton?: boolean;
};

const ErrorActions = ({
  hasQuitButton,
  hasReportAdminButton,
}: ActionsButtons) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const loadingScreenErrors = useSelector(selectLoadingScreenErrors);
  const [isPendingActiveQuest] = useActionStatus(getActiveQuest);
  const [isPendingScheduleCommit] = useActionStatus(getScheduleCommit);
  const [isPendingSchedule] = useActionStatus(getSchedules);
  const [isReportingIssues] = useActionStatus(reportLoadingErrors);

  const isPending = useMemo(
    () => isPendingActiveQuest || isPendingScheduleCommit || isPendingSchedule,
    [isPendingActiveQuest, isPendingScheduleCommit, isPendingSchedule],
  );

  const quit = useCallback(() => {
    localStorage.removeItem('hasReloadedDueToRedis')
    dispatch(unsubscribeFromChatroom());
    dispatch(logout());
    history.replace('/');
  }, [dispatch, history]);

  const report = useCallback(() => {
    const errorMessages = ((loadingScreenErrors || []) as ErrorData[]).map(
      (err) => {
        const { errorCode = 'errorUnindentifiedError', errorInformation } = err;
        const errorMessage = errorInformation
          ? (localizedStrings as any)[errorCode](errorInformation)
          : (localizedStrings as any)[errorCode];
        return `<p>${errorMessage}</p>`;
      },
    );

    dispatch(reportLoadingErrors(errorMessages.join('<br>')));
  }, [dispatch, loadingScreenErrors]);

  return (
    <div>
      {hasQuitButton ? (
        <ButtonPrimary onClick={quit}>
          {localizedStrings.quitErrorBtnText}
        </ButtonPrimary>
      ) : null}

      {hasReportAdminButton ? (
        <ButtonPrimary
          isLoading={isPending || isReportingIssues}
          onClick={report}>
          {localizedStrings.reportErrorBtnText}
        </ButtonPrimary>
      ) : null}
    </div>
  );
};

export default ErrorActions;