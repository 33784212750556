import { AxiosResponse } from 'axios';
import { sleep } from '../helpers/helperFunctions';

const MAX_RETRY_ATTEMPTS = 1;
const WAITING_TIME = 1000;

function axiosResponseTransformation<OutputType>() {
  return (response: AxiosResponse) => response.data as OutputType;
}

export function extractErrorMessage(response: AxiosResponse): string {
  if (response.data?.stack && response.data.stack.includes('AbortError: Redis')) {
    return 'errorRedis';
  }

  if (response.data?.errorCode) {
    return response.data.errorCode;
  }

  if (response.status === 401) {
    return 'errorAuthentication';
  }

  if (response.status === 502 || response.status === 408) {
    return 'errorBackendDown';
  }

  return 'errorDefault';
}


export function baseService<InputType, OutputType>(
  request: (data: InputType) => Promise<AxiosResponse>,
  transformation = axiosResponseTransformation<OutputType>(),
  retryAttempts = 0,
) {
  return async (data: InputType): Promise<OutputType> => {
    try {
      const response = await request(data);
      return transformation(response) as OutputType;
    } catch (error) {
      // Extract error by response
      if (error.response) {
        throw new Error(extractErrorMessage(error.response));
      }
      else if (retryAttempts < MAX_RETRY_ATTEMPTS) {
        const newRetryAttempts = retryAttempts + 1;
        await sleep(newRetryAttempts * WAITING_TIME);
        return baseService(request, transformation, newRetryAttempts)(data);
      } else {
        throw new Error('errorServerDown');
      }
    }
  };
}