import { baseService, extractErrorMessage } from 'modules/baseService';
import {
  EditSchedulePayload,
  GetScheduleCommitServiceResponse,
  SubmitCommitPayload,
} from './typings';
import HttpClient from 'services/HttpClient';
import { ScheduleMap } from 'models/Schedule';

const baseUrl = '/schedules';

export const submitScheduleCommit = baseService<SubmitCommitPayload, void>(
  (data) => HttpClient.post(`${baseUrl}/`, data),
);

export const getScheduleCommit = async (
  questId: string,
): Promise<GetScheduleCommitServiceResponse> => {
  try {
    const result = await HttpClient.get(`${baseUrl}/config?questId=${questId}`);
    return {
      status: 'success',
      data: result.data,
    };
  } catch (err) {
    if (err.response.data?.errorCode) {
      // It returns a complex object in order to be able to handle additional information
      // and pass error information to the reducer handling rejected thunks.
      return {
        status: 'failed',
        error: err,
        errorCode: err.response.data.errorCode,
        errorInformation: err.response.data?.additionalInformation,
      };
    } else {
      // It throws en error here in order to have a default behaviour when the error
      // does not contain additional information about the failure of the request.
      const errorMessage = extractErrorMessage(err.response);

      if (errorMessage === 'errorRedis') {
        const hasReloadedDueToRedis = localStorage.getItem('hasReloadedDueToRedis');

        if (!hasReloadedDueToRedis) {
          localStorage.setItem('hasReloadedDueToRedis', 'true');
          window.location.replace('/');
        }
        throw new Error('errorRedis');
      } else {
        throw new Error(errorMessage);
      }
    }
  }
};

export const editSchedule = baseService<EditSchedulePayload, ScheduleMap>(
  (data) => HttpClient.post(`${baseUrl}/update`, data),
);
